<!--电梯模块--通行记录页--表格组件-->
<template>
  <div>
    <a-table
      :columns="columns"
      :dataSource="data"
      :scroll="scroll"
      :pagination="pagination"
      :loading="loading"
      @change = "onchange"
    >
     <span slot="result" slot-scope="text">
        <a-badge :status="text|statusTypeFilter" :text ="changeStaus(text)"></a-badge>
     </span>
     <span slot="syncResult" slot-scope="text">
        <a-badge :status="text|statusTypeSyncFilter" :text ="changeSyncStaus(text)"></a-badge>
     </span>

    </a-table>
  </div>
</template>

<script>
import {
  tableSort
} from "@/utils/utils";
const statusMap = {
  1:{
    status:'success',
    text:'elevatorQrCodeRecords.s5'
  },
  2:{
    status:'error',
    text:'elevatorQrCodeRecords.s6'
  }
}
export default {
  name: "qrCodeHistoryTable",
  props: {
    data: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
    pagination: {
      type: Object,
    },
    scroll:{
      type: Object,
    }
  },
  data() {
    return {
      statusSyncMap:{
        0:{
          status:'error',
          text:'失败'
        },
        1:{
          status:'error',
          text:'信息有误'
        },
        10:{
          status:'processing',
          text:'上传中'
        },
        255:{
          status:'success',
          text:this.$t('general.a6')
        }
      },
      columns: [
        {
          title: "NO",
          dataIndex: "key",
          width: 50,
          ellipsis: true,
          scopedSlots: { customRender: "NO" },
          fixed: "left",
        },
        {
          title: this.$t('elevatorQrCodeRecords.td1'),
          dataIndex: "datetime",
          width: 160,
          sorter: (a, b) => {
            return tableSort(a, b, "datetime");
          },
          ellipsis: true,
        },
        {
          title: this.$t('elevatorQrCodeRecords.td2'),
          dataIndex: "personName",
          width: 110,
          sorter: (a, b) => {
            return tableSort(a, b, "personName");
          },
          ellipsis: true,
        },
        {
          title: this.$t('elevatorQrCodeRecords.td13'),
          dataIndex: "roomNum",
          width: 90,
          sorter: (a, b) => {
            return tableSort(a, b, "roomNum");
          },
          ellipsis: true,
        },
        {
          title: this.$t('elevatorQrCodeRecords.td3'),
          dataIndex: "personNum",
          width: 130,
          sorter: (a, b) => {
            return tableSort(a, b, "personNum");
          },
          ellipsis: true,
        },
        {
          title: this.$t('elevatorQrCodeRecords.td4'),
          dataIndex: "wechatApplyId",
          width: 160,
          sorter: (a, b) => {
            return tableSort(a, b, "wechatApplyId");
          },
          ellipsis: true,
        },
        {
          title: this.$t('elevatorQrCodeRecords.td5'),
          dataIndex: "valid",
          scopedSlots: { customRender: "result" },
          width: 120,
          sorter: (a, b) => {
            return tableSort(a, b, "valid");
          },
          ellipsis: true,
        },
        {
          title: this.$t('elevatorQrCodeRecords.td6'),
          dataIndex: "startDate",
          width: 160,
          sorter: (a, b) => {
            return tableSort(a, b, "startDate");
          },
          ellipsis: true,
        },
        {
          title: this.$t('elevatorQrCodeRecords.td7'),
          dataIndex: "spanTime",
          width: 100,
          sorter: (a, b) => {
            return tableSort(a, b, "spanTime");
          },
          ellipsis: true,
        },
        {
          title: this.$t('elevatorQrCodeRecords.td8'),
          dataIndex: "startFloor",
          width: 100,
          sorter: (a, b) => {
            return tableSort(a, b, "startFloor");
          },
          ellipsis: true,
        },
        {
          title: this.$t('elevatorQrCodeRecords.td9'),
          dataIndex: "equipmentName1",
          width: 140,
          sorter: (a, b) => {
            return tableSort(a, b, "equipmentName1");
          },
          ellipsis: true,
        },
        {
          title: this.$t('elevatorQrCodeRecords.td10'),
          dataIndex: "equipmentName2",
          width: 180,
          sorter: (a, b) => {
            return tableSort(a, b, "equipmentName2");
          },
          ellipsis: true,
        },
        {
          title: this.$t('elevatorQrCodeRecords.td11'),
          dataIndex: "siteName",
          width: 120,
          sorter: (a, b) => {
            return tableSort(a, b, "siteName");
          },
          ellipsis: true,
        },
        {
          title: this.$t('elevatorQrCodeRecords.td12'),
          dataIndex: "description",
          width: 140,
          sorter: (a, b) => {
            return tableSort(a, b, "description");
          },
          ellipsis: true,
        },
         {
          title: this.$t('elevatorQrCodeRecords.td14'),
          dataIndex: "syncDate",
          width: 160,
          sorter: (a, b) => {
            return tableSort(a, b, "syncDate");
          },
          ellipsis: true,
        },
        {
          title: this.$t('elevatorQrCodeRecords.td15'),
          dataIndex: "syncResult",
          scopedSlots: { customRender: "syncResult" },
          width: 100,
          sorter: (a, b) => {
            return tableSort(a, b, "syncResult");
          },
          fixed: "right",
        },
      ],
    };
  },

  watch: {
  },
  methods: {
    onchange(pagination){
      this.$emit("update:pagination", pagination);
      this.$emit("refresh",false);
    },
    changeStaus(text){
      switch(text){
        case 1:return this.$t('elevatorQrCodeRecords.s5') ;break;
        case 2:return this.$t('elevatorQrCodeRecords.s6') ;break;
      }
    },
    changeSyncStaus(text){
        switch(text){
          case 0:return this.$t('elevatorQrCodeRecords.s2') ;break;
          case 1:return this.$t('elevatorQrCodeRecords.s3') ;break;
          case 10:return this.$t('elevatorQrCodeRecords.s4') ;break;
          case 255:return this.$t('elevatorQrCodeRecords.s1') ;break;
        }
    }
  },
  filters: {
    statusFilter(type,name){
        var temp = 1
        if (type!==1){
            temp = 2
        }

      return statusMap[temp].text
    },
    statusTypeFilter(type){
        var temp = 1
        if (type!==1){
            temp = 2
        }
        return statusMap[temp].status
    },
    statusSyncFilter(type){
      return this.statusSyncMap[type].text
    },
    statusTypeSyncFilter(type){
      return this.statusSyncMap[type].status
    }
  }
};
</script>

<style scoped>

</style>